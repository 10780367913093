import { css } from "aphrodite"
import getStyles from "./Blog.styles"
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import SiteContext from "../../contexts/SiteContext/SiteContext";


export const Blog = () => {

    const { posts, colorTheme, isMobileView } = useContext(SiteContext)
    const styles = getStyles(colorTheme, isMobileView)

    console.log(isMobileView, 'isMobileView')
    const [hoveredPostIndex, setHoveredPostIndex] = useState(null)

    const onMouseEnter = (index) => {
        setHoveredPostIndex(index)
    }

    const onMouseLeave = () => {
        setHoveredPostIndex(null)
    }

    return (
        <div className={css(styles.blogContainer)} >
            <ul style={{
                listStyle: 'none',
                listStyleType: 'none',
                fontFamily: 'cutive mono, monospace',
            }}>
                {posts.map((post, index) => (
                    <li key={post.fileName}>
                        <Link onMouseEnter={() => onMouseEnter(index)} onMouseLeave={onMouseLeave} className={index === hoveredPostIndex ? css(styles.blogLinkActive) : css(styles.blogLink)} to={`/blog/${post.slug}`}>
                            <span >{post.date}: {post.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}