import { StyleSheet } from 'aphrodite'

const getStyles = (colorTheme, isMobileView) =>
    StyleSheet.create({
        blogLink: {
            color: colorTheme.textColor,
            textDecoration: 'none',
        },
        blogLinkActive: {
            color: colorTheme.accentColor,
            textDecoration: 'none',
        },
        blogContainer: {
            marginTop: '80px',
            padding: isMobileView ? '0 20px' : '0 22%',
            height: 'calc(100vh - 80px)',
            overflowY: 'auto',
        },
        postFooter: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0',
            color: colorTheme.accentColor
        }
    })

export default getStyles
